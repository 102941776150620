.gen-header {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.gen-header-label {
    min-width: 110ch;
    white-space: nowrap;
}

.gen-header-line {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 15px;
}

.gen-header-required {
    margin-bottom: 15px;
}

.gen-header-input {
    margin-bottom: 15px;
}