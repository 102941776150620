/* General Container */
.white-container {
    font-family: 'Roboto', Arial, sans-serif;
    color: #333;
    background-color: #f9f9f9;
    min-height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 20px;
    box-sizing: border-box;
}

/* Content Wrapper */
.white-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
}

/* Text Section */
.white-text {
    max-width: 50%;
    text-align: left;
}

.white-title {
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 1.3;
    margin: 0 0 20px;
    color: #222;
}

.blue-highlight {
    color: #3182ce;
}

.white-subtitle {
    font-size: 1.2rem;
    font-weight: 400;
    color: #666;
    margin-bottom: 20px;
}

/* Features Section */
.white-features {
    margin-bottom: 30px;
}

.white-feature-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: #333;
}

.white-feature-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.white-feature-item {
    font-size: 1rem;
    color: #555;
    margin: 5px 0;
    line-height: 1.5;
}

/* Image Section */
.white-image-container {
    max-width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.white-image {
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Responsive Design */
@media (max-width: 768px) {
    .white-content {
        flex-direction: column;
        text-align: center;
    }

    .white-text {
        max-width: 100%;
        margin-bottom: 20px;
    }

    .white-image-container {
        max-width: 100%;
    }
}
