.price-table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    font-size: 16px;
}

.price-feature {
    margin-bottom: 50px;
}

.price-feature:last-child {
    margin-bottom: 0;
}

.price-section {
    margin-bottom: 50px;
}
.price-section:last-child {
    margin-bottom: 0;
}

.divider-shadow {
    border-top: 1px solid #A9A9A9;
    margin: 20px 0;
    opacity: 40%;
}