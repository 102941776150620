/* Container for the top bar */
.top-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #282c34;
    color: white;
}

.top-bar .button:last-child {
    margin-right: 0;
}

.top-bar-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 1500px;
    height: 40px;
    gap: 10px;
    padding: 10px;
}

/* Title styling */
.title {
    margin: 0;
    font-size: 24px;
}

/* Base button styling */
.top-bar-button {
    padding: 10px 15px;
    margin: 10px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    min-width: 80px;
}

/* Specific button styles */
.top-bar-login-button {
    background-color: #3182ce;
    color: white;
}

.logout-button {
    background-color: #e53e3e; /* A contrasting color for logout */
    color: white;
}

.top-bar-button:hover {
    opacity: 0.8;
}
