/* Center the login container */
.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 60px); /* Ensure it centers vertically */
    background: linear-gradient(135deg, #3182ce, #63b3ed);
    padding: 0 16px;
}

.login-card {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%; /* Allow it to adjust dynamically */
    max-width: 500px; /* Max width constraint */
    min-width: 300px; /* Min width constraint */
    box-sizing: border-box; /* Ensures padding doesn't affect width */
    border-radius: 10px; /* Optional: Adds rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Adds a subtle shadow */
}

.login-card input {
    width: 100%; /* Span 100% of the container */
    padding: 10px; /* Ensure comfortable spacing inside */
    margin: 10px 0; /* Space between inputs */
    box-sizing: border-box; /* Prevent padding from affecting width */
    border: 1px solid #ccc; /* Optional: Border for styling */
    border-radius: 5px; /* Optional: Rounded corners for inputs */
}

.login-card button {
    padding: 10px 15px; /* Adjust padding for comfortable click area */
    margin: 10px; /* Space above and below */
    background-color: #3182ce; /* Button background */
    color: white; /* Button text color */
    border: none; /* Remove default border */
    border-radius: 5px; /* Optional: Rounded corners */
    cursor: pointer; /* Indicate it's clickable */
    font: 16px Arial;
    width: 80px;
    height: 40px;
}

.login-card button:hover {
    opacity: 0.9;
}

.login-error-message {
    color: red;
}

