.footer {
    width: 100%;
    max-width: 1500px;
    display: flex;
    justify-content: flex-start;
    color: white;
    margin-top: auto;
    margin-right: auto;
    margin-left: auto;
}

.footer-content {
    display: flex;
    justify-content: flex-start;
}

.footer-link {
    font-size: 0.9rem;
    text-decoration: none;
    color: #3d4852;
}

.footer-link:hover {
    font-weight: bold;
    transition: 0.5s;
}