/* Reset for body and HTML */
html, body #root {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

#root {
  display: flex;
  flex-direction: column;
}

/* Make the container responsive */
.container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  width: 1500px;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.line {
  gap: 10px;
  display: flex;
  margin-bottom: 15px;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.center {
  justify-content: center;
  align-items: center;
}

.method {
  display: flex;
  flex: 1;
}

.dropdown {
  display: flex;
  flex: 1;
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #d2d6dc;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 1rem;
  max-width: 100px;
}

h1 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #323f4b;
}

.form-group {
  margin-bottom: 15px;
}

.method-path {
  display: flex;
  align-items: center;
}

.method-path select {
  flex: 1;
  flex-basis: 30%;
}

label {
  font-weight: bold;
  color: #3d4852;
}

.textarea {
  flex: 1;
  border: 1px solid #d2d6dc;
  border-radius: 4px;
  font-size: 0.9rem;
  background-color: #f7fafc;
  field-sizing: content;
}

.one-line-text {
  height: 45px;
  padding-left: 8px;
}

.test {
  display: flex;
  flex-direction: column;
}

.vertical-block {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0 0 0 15px;
  min-width: 300px;
  margin-bottom: 10px;
}

.vertical-block:last-child {
  margin-right: 0;
}

.path-input {
  /* width: 100%; */
  height: 25px;
  padding: 10px;
  border: 1px solid #d2d6dc;
  border-radius: 4px;
  font-size: 0.9rem;
  background-color: #f7fafc;  
  flex: 4;
}

.summary {
  flex: 1;
  height: 25px;
  padding: 10px;
  border: 1px solid #d2d6dc;
  border-radius: 4px;
  font-size: 0.9rem;
  resize: none;
  background-color: #f7fafc;
}

.response-textarea {
  min-height: 250px;
  white-space: pre-wrap;
  resize: vertical;
  field-sizing: content;
}

textarea {
  flex-grow: 1;
  width: 100%;
  height: 100%; /* Allow the textarea to expand vertically */
  resize: both; /* Allow manual resizing if needed */
  box-sizing: border-box; /* Prevent overflow from padding */
  padding: 8px;
  border: 1px solid #ddd;
}

.textarea:focus, .dropdown:focus, .pathInput:focus {
  border-color: #3182ce;
  outline: none;
}

.add-button {
  background-color: #49cc90;
}

.button:hover {
  filter: brightness(85%);
}

a {
  text-decoration: none;
}
