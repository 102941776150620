.button {
    color: white;
    min-width: 80px;
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
}

/* Blue button */
.button-blue {
    background: #1e90ff; /* Primary blue */
}

.button-blue:hover {
    background: #1c86ee; /* Darken on hover */
}

/* Red button */
.button-red {
    background: #e53e3e; /* Primary red */
}

.button-red:hover {
    background: #e63939; /* Darken on hover */
}

.button-green {
    background: #006400;
}

.button-disabled-true {
    background: #3d4852; /* Grayed out */
    pointer-events: none; /* Prevents any interaction */
}

/* Remove hover effect for disabled button */
.button-disabled-true:hover {
    background: #3d4852; /* Ensure it remains gray on hover */
}