.topup-plan {
    border: 1px #1c86ee solid;
    border-radius: .75rem;
    padding: 0 15px;
    width: 300px;
}

.topup-plan-popular {
    border-color: #49cc90;
}

.topup-line {
    margin-top: 15px;
    justify-content: center;
}

.topup-btn-contaner {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
}