.req-resp-textarea {
    flex-grow: 1;
    width: 100%;
    min-height: 150px;
    min-width: 300px;
    max-width: 100%;
    padding: 8px;
    resize: vertical;
    box-sizing: border-box;
    border: 1px solid #d2d6dc;
    border-radius: 4px;
    background-color: #f7fafc;
    tab-size: 4;
}

.req-resp-textarea:last-child {
    margin-right: 0;
}

.req-resp-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.req-resp-tabs {
    display: flex;
    gap: 8px;
}

.req-resp-tab {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background-color: #e2e8f0;
    color: #4a5568;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-weight: 500;
}

.req-resp-tab:hover {
    background-color: #cbd5e0;
    color: #2d3748;
}

.req-resp-tab.active {
    background-color: #007bff;
    color: white;
    box-shadow: 0 2px 4px rgba(0, 123, 255, 0.2);
}

.req-resp-tab.active:hover {
    background-color: #0056b3;
}

.req-resp-format-btn {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.req-resp-format-btn:hover {
    background-color: #0056b3;
}

.req-resp-format-btn-disabled-true {
    background-color: gray;
    cursor: not-allowed;
}

.req-resp-format-btn-disabled-true:hover {
    background-color: gray;
}