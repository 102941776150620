.input-with-error {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
}

.input {
    width: 100%;
    padding: 8px 12px;
    font-size: 1rem;
    border: 1px solid #d2d6dc;
    border-radius: 4px;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.3s;
    background-color: #f7fafc;
}

.input.error {
    border-color: red;
}

.error-message {
    height: 16px; /* Reserve space for the error message */
    font-size: 12px;
    color: red;
    visibility: hidden; /* Hidden by default */
    opacity: 0; /* Invisible by default */
    transition: opacity 0.3s, visibility 0.3s;
    line-height: 16px; /* Ensure consistent vertical alignment */
}

.input.error + .error-message {
    visibility: visible;
    opacity: 1;
}
