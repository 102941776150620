/* Center the login container */
.signup-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 60px); /* Ensure it centers vertically */
    background: linear-gradient(135deg, #3182ce, #63b3ed);
    padding: 0 16px;
}

.signup-card {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%; /* Allow it to adjust dynamically */
    max-width: 500px; /* Max width constraint */
    min-width: 300px; /* Min width constraint */
    box-sizing: border-box; /* Ensures padding doesn't affect width */
    border-radius: 10px; /* Optional: Adds rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Adds a subtle shadow */
}

.signup-card button:hover {
    opacity: 0.9;
}

.reg-error-message {
    color: red;
    margin-bottom: 15px;
}

