.remove-button {
    background-color: #f93e3e;
    padding: 12px;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    width: 150px;
}

.remove-button:hover {
    filter: brightness(85%);
}

.response-block {
    margin-bottom: 10px;
}

.content-type {
    width: fit-content;
    font-size: 0.9rem;
    border-radius: 4px;
    height: 45px;
    padding: 10px;
}

.response-block-meta-line {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 10px;
}

.resp-status-code {
    width: calc(11ch + 12px);
}